<template>
  <div>
    <v-text-field
      v-model="street"
      label="Strasse, Nr"
      autocomplete="street-address"
      @input="input"
    />
    <div class="d-flex">
      <div class="mr-3" style="width: 65px">
        <v-text-field
          v-model.number="plz"
          type="number"
          label="Plz"
          autocomplete="postal-code"
          @input="input"
        />
      </div>
      <div style="flex: 1">
        <v-text-field
          v-model="city"
          label="Ort"
          autocomplete="address-level2"
          @input="input"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AddressFields',
  props: {
    value: Object,
    hideNames: Boolean
  },
  data () {
    return {
      plz: null,
      city: null,
      street: null
    }
  },
  computed: {
    valid () {
      return this.plz !== null && this.plz > 1000 && this.plz < 9999 &&
        this.city !== null && this.city.length > 1 &&
        this.street !== null && this.street.length > 1
    }
  },
  methods: {
    input () {
      this.$emit('input', {
        plz: this.plz,
        city: this.city,
        street: this.street
      })
    }
  },
  watch: {
    value: {
      handler (value) {
        if (value) {
          this.plz = value.plz || null
          this.city = value.city || null
          this.street = value.street || null
        }
      },
      immediate: true
    },
    valid (valid) {
      this.$emit('valid', valid)
    }
  }
}
</script>
