<template>
  <v-input
    :label="label"
    class="input-vue-editor mt-3"
  >
    <div>
      <vue-editor
        ref="editor"
        v-model="val"
        :editor-options="{
          theme: 'snow',
          format: ['bold', 'italic', 'list', 'link']
        }"
        :editor-toolbar="[
          ['bold', 'italic'],
          [{ 'list': 'bullet' }, { 'list': 'ordered'}],
          ['link'],
          ['clean']
        ]"
        @focus="$emit('focus')"
      >
      </vue-editor>
    </div>
  </v-input>
</template>

<script>
import { VueEditor } from 'vue2-editor'

export default {
  name: 'QuillEditor',
  components: {
    VueEditor
  },
  props: {
    label: String,
    value: String
  },
  data () {
    return {
      val: null
    }
  },
  watch: {
    value: {
      handler (value) {
        this.val = value
      },
      immediate: true
    },
    val (val) {
      this.$emit('input', val)
    }
  },
  mounted () {
    this.$refs.editor.quill.focus()
  }
}
</script>

<style scoped>
.input-vue-editor >>> .v-input__slot {
  display: block;
}
</style>