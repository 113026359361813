<template>
  <div class="my-3">
    <div class="text-subtitle-1 mb-2">{{ label }}</div>
    <address-fields v-model="val"/>
  </div>
</template>

<script>
import AddressFields from '@/components/base/AddressFields'

export default {
  name: 'PlugAddressEdit',
  components: { AddressFields },
  props: {
    value: Object,
    label: { type: String, default: 'Adresse' },
    prefill: Boolean
  },
  data () {
    return {
      val: null
    }
  },
  watch: {
    value: {
      handler (value) {
        if (value) {
          this.val = value
        } else if (this.val === null && this.prefill) {
          this.val = {
            plz: this.$root.user.address?.plz,
            city: this.$root.user.address?.city,
            street: this.$root.user.address?.street
          }
        }
      },
      immediate: true
    },
    val (val) {
      this.$emit('input', val)
    }
  }
}
</script>
