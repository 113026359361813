<template>
  <v-container>
    <event-card :event="event" detail hide-participants-count class="mb-5"/>

    <div class="text-center">
      <v-btn text small :loading="downloading" @click="addToCal">
        <v-icon left>mdi-calendar</v-icon>
        <span>Zum Kalender hinzufügen</span>
      </v-btn>

      <div v-if="event.participation">
        <v-divider class="my-5"/>
        <div v-if="!participating">
          <div v-if="!bookedUp">
            <div v-if="event.limit" class="mb-2">Es hat noch freie Plätze :)</div>
            <v-btn color="primary" :loading="submitting" @click="participate">Anmelden</v-btn>
          </div>
          <div v-else>Anlass ausgebucht :(</div>
        </div>
        <div v-else>
          <div class="d-flex justify-center mb-2">
            <v-icon color="success" class="mr-2">mdi-check-circle</v-icon>
            <span>Du bist angemeldet</span>
          </div>
          <v-btn text small color="error" :loading="submitting" @click="participate">Abmelden</v-btn>
        </div>

        <v-list v-if="participantsList && participantsList.length > 0" class="text-left my-3">
          <v-divider/>
          <v-subheader>Teilnehmer</v-subheader>
          <v-list-item
            v-for="(participant, i) in participantsList"
            :key="i"
          >
            <user-avatar :user="participant" small class="mr-3"/>
            <span>{{ participant.name }}</span>
          </v-list-item>
        </v-list>

        <div v-else class="mt-10 mb-5 text-body-2 font-italic">
          Noch keine Anmeldungen
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import FileMixin from '@/mixins/FileMixin.js'
import EventCard from '@/components/events/EventCard'
import UserAvatar from '@/components/base/UserAvatar'

export default {
  name: 'EventDetail',
  mixins: [FileMixin],
  components: { EventCard, UserAvatar },
  inject: ['config'],
  props: {
    event: Object
  },
  data () {
    return {
      participants: null,
      submitting: false,
      downloading: false
    }
  },
  computed: {
    participating () {
      return this.participants?.find(p => p.userId === this.$root.userId)
    },
    participantsList () {
      return this.participants?.map(p => {
        const user = this.$root.users[p.userId]
        p.name = user?.name
        p.img = user?.img
        return p
      }).sort((a, b) => a.name < b.name ? -1 : 1)
    },
    bookedUp () {
      return this.event.limit && this.event.limit === this.participants?.length
    }
  },
  methods: {
    async participate () {
      this.submitting = true
      const participateEventFunc = this.$fb.fn.httpsCallable('participateEvent')
      const response = await participateEventFunc({
        eventId: this.event.id
      })
      if (!response.data.ok) {
        console.log(response.data.err) // TODO, maybe show toast with result if OK (because it toggles)
      }
    },
    async addToCal () {
      this.downloading = true
      const datebook = await import('datebook')
      const ical = new datebook.ICalendar({
        title: this.event.title,
        location: this.event.address ? (this.event.address.street + ', ' + this.event.address.plz + ' ' + this.event.address.city) : '',
        description: this.event.text || '',
        start: this.event.begin.toDate(),
        end: this.event.end.toDate()
      })
      // trigger download
      if (this.$root.native) {
        const icalStr = ical.render()
        this.openFile('event.ics', icalStr, 'text/calendar')
      } else {
        ical.download()
      }
      this.downloading = false
    }
  },
  created () {
    this.unsubscribe = this.$fb.db.collection('events/' + this.event.id + '/participants')
      .orderBy('ts')
      .onSnapshot(snap => {
        const participants = []
        snap.forEach(doc => {
          participants.push(doc.data())
        })
        this.participants = participants
        this.submitting = false
      })
  },
  beforeDestroy () {
    this.unsubscribe()
  }
}
</script>
