<template>
  <v-container>
    <v-alert
      v-if="moderated"
      color="grey"
      dark
      icon="mdi-account-supervisor-circle"
      elevation="2"
    >
      Der Event wird vor dem Publizieren überprüft.
    </v-alert>

    <v-form>
      <img-upload v-model="img"/>

      <v-text-field v-model="title" label="Titel" @focus="lastFocus = 'title'"/>

      <quill-editor
        v-model="text"
        label="Text"
        @focus="lastFocus = 'text'"
      />

      <v-select
        v-if="calsOpts"
        v-model="calId"
        label="Kalender"
        :items="calsOpts"
        class="mb-4"
        @focus="lastFocus = null"
      />

      <date-time v-model="begin" label="Beginn"/>
      <date-time v-model="end" label="Ende" :min="begin"/>

      <plug-address-edit v-model="address" label="Ort"/>

      <v-checkbox v-model="participation" label="Mit Anmeldung" hint="Anmeldung und Teilnehmerliste"/>
      <v-text-field v-if="participation" v-model.number="limit" label="Limite" hint="Maximale Anzahl Teilnehmer (leer = unbegrenzt)"/>

      <div class="d-flex justify-center my-5">
        <v-btn icon :disabled="!lastFocus" @click="showEmojiPicker = !showEmojiPicker">
          <v-icon>mdi-emoticon-outline</v-icon>
        </v-btn>

        <v-btn color="primary" rounded :disabled="!formValid" :loading="submitting" class="mx-3" @click="submitEvent">Speichern</v-btn>

        <v-btn icon style="visibility: hidden"/>
      </div>

      <div v-if="event && event.id" class="text-center">
        <v-btn color="error" text small @click="showDeleteEvent = true">Löschen</v-btn>
      </div>
    </v-form>

    <v-dialog
      v-model="showEmojiPicker"
      transition="dialog-bottom-transition"
    >
      <v-emoji-picker v-if="showEmojiPicker" @select="selectEmoji"/>
    </v-dialog>

    <confirm-dlg
      v-model="showDeleteEvent"
      text="Beitrag löschen?"
      @ok="deleteEvent"
    />
  </v-container>
</template>

<script>
import { VEmojiPicker } from 'v-emoji-picker'
import DateTime from '@/components/base/DateTime'
import ConfirmDlg from '@/components/base/ConfirmDlg'
import ImgUpload from '@/components/base/ImgUpload'
import QuillEditor from '@/components/base/QuillEditor'
import PlugAddressEdit from '@/components/plugs/PlugAddressEdit'

export default {
  name: 'EventEdit',
  components: {
    DateTime,
    ConfirmDlg,
    ImgUpload,
    QuillEditor,
    PlugAddressEdit,
    VEmojiPicker
  },
  inject: ['config'],
  props: {
    event: Object
  },
  data () {
    return {
      title: null,
      text: null,
      begin: null,
      end: null,
      participation: null,
      limit: null,
      img: null,
      calId: null,
      address: null,
      submitting: false,
      lastFocus: null,
      showEmojiPicker: false,
      showDeleteEvent: false
    }
  },
  computed: {
    calsOpts () {
      if (!this.$root.cals) return null
      return Object.keys(this.$root.cals).map(calId => ({ text: this.$root.cals[calId].name, value: calId }))
    },
    moderated () {
      if (this.$root.isAdmin) return false
      return this.$root.views[this.config.id].moderated && !this.$root.views[this.config.id].moderators?.includes(this.$root.userId)
    },
    formValid () {
      return this.title && this.begin > 0 && this.end > 0 && this.begin < this.end && (this.$root.isAdmin || !this.$root.cals || this.calId)
    }
  },
  methods: {
    selectEmoji (emoji) {
      if (this[this.lastFocus] === null) {
        this[this.lastFocus] = ''
      }
      this[this.lastFocus] += emoji.data
      this.showEmojiPicker = false
    },
    async submitEvent () {
      this.showEmojiPicker = false
      this.submitting = true
      const saveEventFunc = this.$fb.fn.httpsCallable('saveEvent')
      const event = {
        id: this.event ? this.event.id : null,
        viewId: this.config.id,
        title: this.title,
        text: this.text,
        begin: this.begin,
        end: this.end,
        address: this.address,
        img: this.img,
        calId: this.calId,
        participation: this.participation || false,
        limit: this.limit
      }
      const response = await saveEventFunc(event)
      if (response.data.ok) {
        this.$emit('save'/*, {
          id: response.data.id,
          ...response.data.data,
          begin: this.$fb.fb.firestore.Timestamp.fromMillis(response.data.data.begin._seconds * 1000),
          end: this.$fb.fb.firestore.Timestamp.fromMillis(response.data.data.end._seconds * 1000)
        } */)
      }
      this.submitting = false
    },
    async deleteEvent () {
      const deleteEventFunc = this.$fb.fn.httpsCallable('deleteEvent')
      const response = await deleteEventFunc({ id: this.event.id })
      if (response.data.ok) {
        this.$emit('delete')
      }
    }
  },
  watch: {
    begin (begin) {
      if (begin && !this.end) {
        this.end = begin + 1 * 60 * 60 * 1000 // add 1h
      }
    },
    lastFocus (lastFocus) {
      if (lastFocus === null) {
        this.showEmojiPicker = false
      }
    }
  },
  created () {
    if (this.event) {
      this.title = this.event.title
      this.text = this.event.text
      this.begin = this.event.begin ? this.event.begin.toMillis() : null
      this.end = this.event.end ? this.event.end.toMillis() : null
      this.address = { ...this.event.address }
      this.img = this.event.img
      this.calId = this.event.calId || null
      this.participation = this.event.participation || null
      this.limit = this.event.limit || null
    } else {
      if (this.config.calId) {
        this.calId = this.config.calId
      }
    }
  }
}
</script>
