export default {
  methods: {
    html2text (html) {
      return html
        .replace(/<br>/gi, '\n')
        .replace(/<\/p>/gi, '\n')
        .replace(/(<([^>]+)>)/gi, '')
        .replace(/\n+/g, '\n')
    }
  }
}
