<template>
  <v-card :outlined="!$listeners.click" v-on="$listeners.click ? { click: () => $emit('click', eventData) } : {}">
    <v-card-title class="d-flex align-start justify-space-between flex-nowrap" :class="{ 'event-title-cal': cal }">
      <div>{{ eventData ? eventData.title : '' }}</div>
      <!-- <img-user v-if="userImg" :img="userImg"/> -->
    </v-card-title>

    <div v-if="cal" class="text-caption px-4" :class="cal.color + '--text'">{{ cal.name }}</div>

    <v-card-text v-if="eventData">
      <v-row>
        <v-col v-if="eventData.img" cols="12" sm="4">
          <img-cmp :img="eventData.img" />
        </v-col>
        <v-col cols="12" :sm="eventData.img ? 8 : 12">
          <div v-if="eventData.text" class="mb-3">
            <div v-if="!detail" v-text="html2text(eventData.text)" class="text-short"/>
            <div v-else v-html="eventData.text" class="html-text"/>
          </div>
          <div class="mb-3">{{ eventData | daterange }}</div>
          <plug-address-view :item="eventData" short class="mt-3"/>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions>
      <v-spacer/>
      <v-btn v-if="event.participation && !hideParticipantsCount" text :color="participantsCount > 0 ? 'blue' : 'grey'">
        <v-icon left>mdi-account-multiple-outline</v-icon>
        {{ participantsCount }} {{ eventData.limit ? ('/ ' + eventData.limit) : '' }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
// import { VEmojiPicker } from 'v-emoji-picker'
import ImgCmp from '@/components/base/ImgCmp'
import PlugAddressView from '@/components/plugs/PlugAddressView'
import HelpersMixin from '@/mixins/HelpersMixin'

export default {
  name: 'EventCard',
  components: { ImgCmp, PlugAddressView },
  mixins: [HelpersMixin],
  inject: ['config'],
  props: {
    eventId: String,
    event: Object,
    detail: Boolean,
    hideParticipantsCount: Boolean
  },
  data () {
    return {
      eventData: null
    }
  },
  computed: {
    cal () {
      return this.event.calId
        ? this.$root.cals[this.event.calId]
        : null
    },
    participantsCount () {
      return this.eventData && this.eventData.participantsCount ? this.eventData.participantsCount : 0
    }
  },
  methods: {
    // async react (emoji) {
    //   const reactEventFunc = this.$fb.fn.httpsCallable('reactEvent')
    //   const response = await reactEventFunc({
    //     eventId: this.eventId || this.event.id,
    //     emoji
    //   })
    //   if (response.data.ok) {
    //     this.eventData.reactions = response.data.reactions
    //     this.eventData.commentsCount = response.data.commentsCount
    //   }
    // }
  },
  watch: {
    event (event) {
      if (event) {
        this.eventData = { ...this.event }
      }
    }
  },
  created () {
    if (this.event) {
      this.eventData = { ...this.event }
    } else {
      this.$fb.db.doc('events/' + this.eventId).get()
        .then(doc => {
          this.eventData = {
            id: this.eventId,
            ...doc.data()
          }
        })
    }
  }
}
</script>

<style scoped>
/* .reaction-btn {
  height: 30px !important;
  min-width: 50px !important;
  border: thin solid lightgrey;
}
.reaction-btn-reacted {
  border: thin solid grey;
} */
.event-title-cal {
  padding-bottom: 8px;
}
</style>
