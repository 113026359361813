<template>
  <div class="d-flex flex-column" style="height: calc(100vh - 2 * var(--bar-height)); overflow: hidden;">
    <v-toolbar class="cal-toolbar grey lighten-3" style="flex-grow: 0; z-index: 1">
      <v-btn-toggle v-model="mode" mandatory dense>
        <v-btn icon small><v-icon>mdi-calendar-week-begin</v-icon></v-btn>
        <v-btn icon small><v-icon>mdi-calendar-week</v-icon></v-btn>
        <v-btn icon small><v-icon>mdi-calendar-month</v-icon></v-btn>
      </v-btn-toggle>
      <v-spacer></v-spacer>
      <v-toolbar-title @click="showDatePicker = !showDatePicker">{{ title }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon small @click="prev"><v-icon>mdi-chevron-left</v-icon></v-btn>
      <v-btn icon @click="today"><v-icon>mdi-calendar-today</v-icon></v-btn>
      <v-btn icon small @click="next"><v-icon>mdi-chevron-right</v-icon></v-btn>

      <template slot="extension">
        <!-- <v-select
          v-model="calIds"
          :items="calsOpts"
          clearable
          multiple
          placeholder="Alle Kalender"
          persistent-placeholder
        >
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index < 2" :color="item.color" dark small>
              <span>{{ item.text }}</span>
            </v-chip>
            <v-chip v-if="index === 2" small>
              <v-icon>mdi-dots-horizontal</v-icon>
            </v-chip>
          </template>
        </v-select> -->
        <v-chip
          v-for="item in calsOpts" :key="item.value"
          :outlined="calIds.indexOf(item.value) === -1"
          :color="item.color"
          dark
          small
          class="cal-chips"
          @click="toggleCal(item.value)"
        >
          {{ item.text }}
        </v-chip>
      </template>
    </v-toolbar>

    <v-alert
      v-if="hiddenEvents && hiddenEvents.length > 0"
      color="grey"
      dark
      icon="mdi-information"
      elevation="2"
      @click="showHidden = !showHidden"
    >
      <v-row align="center">
        <v-col class="grow">
          {{ $tc('hidden_events', hiddenEvents.length) }}
        </v-col>
        <v-col class="shrink">
          <v-btn text small>{{ !showHidden ? 'Anzeigen' : 'Zurück' }}</v-btn>
        </v-col>
      </v-row>
    </v-alert>

    <v-date-picker
      v-if="showDatePicker"
      :value="span === 'month' ? dateStr.substr(0, 7) : dateStr"
      :type="span === 'month' ? 'month' : 'date'"
      :allowed-dates="allowedDates"
      locale="de"
      :first-day-of-week="1"
      :locale-first-day-of-year="4"
      full-width
      no-title
      show-current
      show-week
      @change="pickDate"
    />

      <!-- <v-select
        v-if="cats"
        v-model="catId"
        :items="catsOpts"
        :clearable="catId !== null"
        label="Kategorie"
        filled
      /> -->

    <div v-if="!showHidden" style="flex: 1; overflow: hidden;">
      <div v-if="mode === 0" class="scrollable" style="height: 100%">
        <v-container v-if="visibleEvents.length > 0">
          <event-card
            v-for="item in visibleEvents"
            :key="item.id"
            :event="item"
            class="mb-4"
            @click="event = $event"
          />
        </v-container>

        <v-container v-else-if="events !== null" class="my-6 text-center">
          Keine Events
        </v-container>
      </div>

      <div v-else v-touch="{ left: next, right: prev }" style="height: 100%">
        <v-calendar
          v-model="dateStr"
          ref="calendar"
          :type="span"
          :events="visibleEvents.map(e => ({
            ...e,
            timed: $dayjs(e.begin.toDate()).isSame(e.end.toDate(), 'day'),
            beginDate: e.begin.toDate(),
            endDate: e.end.toDate()
          }))"
          :weekdays="[1, 2, 3, 4, 5, 6, 0]"
          :event-color="event => event.calId ? $root.cals[event.calId].color : 'primary'"
          first-time="06:00"
          interval-count="18"
          locale="de"
          :event-name="event => event.input.title + (event.input.address && event.input.address.city ? (' (' + event.input.address.city + ')') : '')"
          event-start="beginDate"
          event-end="endDate"
          :event-timed="() => span !== 'month'"
          event-overlap-mode="column"
          style="max-height: 100%; border-left: 0; border-right: 0;"
          @click:event="event = $event.event"
          @click:more="mode = 0"
        />
      </div>
    </div>

    <div v-else class="scrollable" style="flex: 1">
      <v-container>
        <event-card
          v-for="item in hiddenEvents"
          :key="item.id"
          :event="item"
          class="mb-3"
          @click="event = $event"
        />
      </v-container>
    </div>

    <v-dialog
      :value="event !== null"
      fullscreen
      hide-overlay
      persistent
    >
      <v-card class="d-flex flex-column" style="height: 100%;">
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="event = null">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-spacer/>
          <v-btn v-if="isEventEditable(event)" icon @click.stop="showEventEdit = true">
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>
          <v-btn v-if="isEventEditable(event)" icon @click.stop="showEventDuplicate = true">
            <v-icon small>mdi-content-duplicate</v-icon>
          </v-btn>
          <v-btn icon @click="event = null">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <div class="scrollable" style="flex: 1;">
          <event-detail
            v-if="event"
            :event="event"
          />
        </div>
      </v-card>
    </v-dialog>

    <fab-btn v-if="canAdd" @click="showEventEdit = true"/>

    <v-dialog
      :value="showEventEdit"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card class="d-flex flex-column" style="height: 100%;">
        <v-toolbar dark color="primary">
          <v-spacer/>
          <v-btn icon dark @click="showEventEdit = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <div class="scrollable" style="flex: 1;">
          <event-edit v-if="showEventEdit" :config="config" :event="event" @save="onSave" @delete="onDelete"/>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog
      :value="showEventDuplicate"
      width="330"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>Daten wählen</v-toolbar-title>
          <v-spacer/>
          <v-btn icon dark @click="showEventDuplicate = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <div>
          <v-date-picker
            v-model="duplicateDates"
            :first-day-of-week="1"
            :locale-first-day-of-year="4"
            multiple
            no-title
            class="mt-5 mx-4"
          />
        </div>
        <v-card-actions>
          <v-spacer/>
          <v-btn :disabled="duplicateDates.length === 0" :loading="duplicating" color="primary" @click="duplicate">Duplizieren</v-btn>
          <v-spacer/>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import EventCard from '@/components/events/EventCard'
import EventDetail from '@/components/events/EventDetail'
import EventEdit from '@/components/events/EventEdit'
import FabBtn from '@/components/base/FabBtn'

export default {
  name: 'EventsView',
  components: {
    EventCard,
    EventDetail,
    EventEdit,
    FabBtn
  },
  props: {
    config: Object
  },
  provide () {
    return {
      config: this.config
    }
  },
  data () {
    return {
      mode: null, // [0:list, 1:week, 2:month]
      dateStr: null,
      events: null,
      event: null, // currently open event
      showDatePicker: false,
      showEventEdit: false,
      showEventDuplicate: false,
      duplicateDates: [],
      duplicating: false,
      calIds: [],
      showHidden: false
    }
  },
  computed: {
    span () {
      return this.mode === 1 ? 'week' : 'month'
    },
    beginDate () {
      const day = this.$dayjs(this.dateStr)
      return this.mode === 1
        ? day.day(1) // first day of week (monday)
        : day.date(1) // first day of month (1.)
    },
    endDate () {
      return this.beginDate.add(1, this.span).subtract(1, 'day')
    },
    beginStr () {
      return this.beginDate.formatDateISO()
    },
    endStr () {
      return this.endDate.formatDateISO()
    },
    title () {
      const day = this.$dayjs(this.dateStr)
      return this.mode === 1
        ? day.formatKw()
        : day.formatMonth()
    },
    visibleEvents () {
      return (this.events || []).filter(e => !e._deleted && e.reviewed !== false && (!e.calId || this.calIds.includes(e.calId)))
      // events.sort((a, b) => a[orderBy] < b[orderBy] ? -1 : 1)
      // if (this.config.orderDir === 'desc') {
      //   events.reverse()
      // }

      // filter events by catId, if set
      // return this.events && this.catId
      //   ? this.events.filter(p => {
      //     if (!p.catIds) return false
      //     const catIds = []
      //     Object.values(p.catIds).forEach(tagCatIds => {
      //       tagCatIds.forEach(catId => {
      //         catIds.push(catId)
      //       })
      //     })
      //     return catIds.includes(this.catId)
      //   })
      //   : this.events
    },
    hiddenEvents () {
      return (this.events || []).filter(e => !e._deleted && e.reviewed === false)
    },
    cals () {
      return this.$root.cals || null
    },
    calsOpts () {
      if (!this.cals) return null
      return Object.keys(this.cals)
        .map(calId => ({ text: this.cals[calId].name, value: calId, color: this.cals[calId].color }))
        .sort((a, b) => a.text < b.text ? -1 : 1)
    },
    canAdd () {
      if (this.$root.isAdmin) return true
      // if view is not protected or current user is moderator
      return !this.$root.views[this.config.id]?.protected || this.$root.views[this.config.id]?.moderators?.includes(this.$root.userId)
    }
  },
  methods: {
    allowedDates (dateStr) {
      return this.$dayjs(dateStr).day() === 1 // only mondays
    },
    today () {
      this.dateStr = this.$dayjs().formatDateISO()
    },
    prev () {
      this.dateStr = this.$dayjs(this.dateStr).subtract(1, this.span).formatDateISO()
    },
    next () {
      this.dateStr = this.$dayjs(this.dateStr).add(1, this.span).formatDateISO()
    },
    pickDate (value) {
      if (value.length < 10) {
        value += '-01'
      }
      this.dateStr = value
      this.showDatePicker = false
    },
    toggleCal (calId) {
      const idx = this.calIds.indexOf(calId)
      if (idx === -1) {
        this.calIds.push(calId)
      } else {
        this.calIds.splice(idx, 1)
      }
    },
    async subscribe () {
      this.unsubscribe()

      this.events = null

      const beginDate = this.beginDate.toDate()
      const endDate = this.$dayjs(this.endStr + 'T23:59:59').toDate()
      const ykw = this.beginDate.format('YYYY:ww')

      let beginEvents = null
      let endEvents = null
      let spanEvents = null

      const mergeEvents = () => {
        if (beginEvents !== null && endEvents !== null && spanEvents !== null) {
          const events = [...new Map([...beginEvents, ...endEvents, ...spanEvents].map(e => [e.id, e])).values()] // array_unique_by_key
          events.sort((a, b) => a.begin < b.begin ? -1 : 1)
          this.events = events
        }
      }

      // get all events with begin in range
      this.unsubscribeBegins = this.$fb.db.collection('events')
        // .where('tagIds', 'array-contains', this.config.tagId)
        .where('begin', '>=', beginDate)
        .where('begin', '<=', endDate)
        .onSnapshot(snap => {
          const events = []
          snap.forEach(doc => {
            events.push({
              id: doc.id,
              ...doc.data()
            })
          })
          beginEvents = events
          mergeEvents()
        })

      // get all events with end in range
      this.unsubscribeEnds = this.$fb.db.collection('events')
        // .where('tagIds', 'array-contains', this.config.tagId)
        .where('end', '>=', beginDate)
        .where('end', '<=', endDate)
        .onSnapshot(snap => {
          const events = []
          snap.forEach(doc => {
            events.push({
              id: doc.id,
              ...doc.data()
            })
          })
          endEvents = events
          mergeEvents()
        })

      // get all events fully overlapping (spanning) range (if more than a week)
      this.unsubscribeSpans = this.$fb.db.collection('events')
        // .where('tagIds', 'array-contains', this.config.tagId)
        .where('kws', 'array-contains', ykw)
        .onSnapshot(snap => {
          const events = []
          snap.forEach(doc => {
            events.push({
              id: doc.id,
              ...doc.data()
            })
          })
          spanEvents = events
          mergeEvents()
        })
    },
    unsubscribe () {
      if (this.unsubscribeBegins) {
        this.unsubscribeBegins()
      }
      if (this.unsubscribeEnds) {
        this.unsubscribeEnds()
      }
      if (this.unsubscribeSpans) {
        this.unsubscribeSpans()
      }
    },
    isEventEditable (event) {
      return event && (event.userId === this.$root.userId || this.$root.isAdmin)
    },
    onSave () {
      this.event = null
      this.showEventEdit = false
    },
    onDelete () {
      this.event = null
      this.showEventEdit = false
    },
    async duplicate () {
      this.duplicating = true
      const saveEventFunc = this.$fb.fn.httpsCallable('saveEvent')
      const beginISO = this.$dayjs(this.event.beginDate).formatDateISO()
      const event = { ...this.event }
      delete event.id
      for (let i = 0; i < this.duplicateDates.length; i++) {
        const date = this.duplicateDates[i]
        const dayDiff = this.$dayjs(date).diff(beginISO, 'day')
        await saveEventFunc({
          ...event,
          begin: this.$dayjs(this.event.beginDate).add(dayDiff, 'day').valueOf(),
          end: this.$dayjs(this.event.endDate).add(dayDiff, 'day').valueOf()
        })
      }
      this.duplicating = false
      this.duplicateDates = []
      this.showEventDuplicate = false
      this.event = null
    },
    back () {
      if (this.showEventEdit) {
        this.showEventEdit = false
        return true
      } else if (this.event) {
        this.event = null
        return true
      }
    }
  },
  watch: {
    mode (mode) {
      localStorage.setItem('events:mode', mode)

      if (mode === 1) {
        this.$nextTick(() => {
          const anHourAgo = this.$dayjs().subtract(1, 'hour').formatTime()
          this.$refs.calendar.scrollToTime(anHourAgo)
        })
      }
    },
    endStr () {
      this.subscribe()
    }
  },
  created () {
    this.mode = parseInt(localStorage.getItem('events:mode') || 0)
    this.calIds = Object.keys(this.cals)
    this.today()
  },
  beforeDestroy () {
    this.unsubscribe()
  }
}
</script>

<style scoped>
.v-btn--icon.v-size--small {
  height: 32px;
  width: 32px;
}
.cal-toolbar >>> .v-toolbar__extension {
  display: block;
  white-space: nowrap;
  overflow-x: auto;
}
.cal-chips {
  border-width: thin;
  border-style: solid;
  margin-right: 4px;
}
</style>
