<template>
  <div class="mb-3">
    <div v-if="label" class="caption caption-label">{{ label }}</div>

    <div class="d-flex align-center">
      <v-text-field
        :value="dateFormatted"
        label="Datum"
        prepend-icon="mdi-calendar"
        readonly
        @click="showDateDlg = true"
      ></v-text-field>
      <v-text-field
        :value="timeStr"
        :disabled="!dateStr"
        label="Zeit"
        prepend-icon="mdi-clock-outline"
        readonly
        class="ml-3"
        @click="showTimeDlg = true"
      ></v-text-field>
      <v-btn icon :disabled="!dateStr && !timeStr" @click="clear">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>

    <v-dialog
      v-model="showDateDlg"
      width="290"
    >
      <v-date-picker
        v-model="dateStr"
        :min="minDate"
        locale="de"
        :first-day-of-week="1"
        :locale-first-day-of-year="4"
        @change="showDateDlg = false"
      />
    </v-dialog>

    <v-dialog
      v-model="showTimeDlg"
      width="290"
    >
      <v-time-picker
        v-if="showTimeDlg"
        v-model="timeStr"
        format="24hr"
        :allowed-minutes="[0, 15, 30, 45]"
        :min="minTime"
        @change="showTimeDlg = false"
      />
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'DateTime',
  props: {
    value: Number, // ms
    min: Number, // ms
    label: String
  },
  data () {
    return {
      dateStr: null,
      timeStr: null,
      showDateDlg: false,
      showTimeDlg: false
    }
  },
  computed: {
    dateFormatted () {
      return this.dateStr ? this.$dayjs(this.dateStr).formatDate() : ''
    },
    minDate () {
      return this.min ? this.$dayjs(this.min).formatDateISO() : null
    },
    minTime () {
      return this.min && this.dateStr === this.minDate ? this.$dayjs(this.min).formatTime() : null
    }
  },
  methods: {
    clear () {
      this.dateStr = null
      this.timeStr = null
    },
    onChange () {
      let ms = null
      if (this.dateStr && this.timeStr) {
        ms = this.$dayjs(this.dateStr + 'T' + this.timeStr + ':00').valueOf()
      }
      this.$emit('input', ms)
    }
  },
  watch: {
    value: {
      handler (value) {
        if (this.value) {
          const day = this.$dayjs(this.value)
          this.dateStr = day.formatDateISO()
          this.timeStr = day.formatTime()
        }
      },
      immediate: true
    },
    dateStr () {
      this.onChange()
    },
    timeStr () {
      this.onChange()
    }
  }
}
</script>
