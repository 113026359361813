var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-input',{staticClass:"input-vue-editor mt-3",attrs:{"label":_vm.label}},[_c('div',[_c('vue-editor',{ref:"editor",attrs:{"editor-options":{
        theme: 'snow',
        format: ['bold', 'italic', 'list', 'link']
      },"editor-toolbar":[
        ['bold', 'italic'],
        [{ 'list': 'bullet' }, { 'list': 'ordered'}],
        ['link'],
        ['clean']
      ]},on:{"focus":function($event){return _vm.$emit('focus')}},model:{value:(_vm.val),callback:function ($$v) {_vm.val=$$v},expression:"val"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }